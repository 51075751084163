export const nomenclaturaSituacaoAluno = {
  aprovado: "PROMOVIDO",
  aprovadoAbreviacao: "PR",
  reprovado: "RETIDO",
  reprovadoAbreviacao: "RET",
  aprovadoPeloConselho: "PROMOVIDO PELO CONSELHO",
  aprovadoPeloConselhoAbreviacao: "PRC",
  transferido: 'TRANSFERIDO',
  transferidoAbreviacao: 'TR',
  evadido: 'EVADIDO',
  evadidoAbreviacao: 'EV',
  falecido: 'FALECIDO',
  falecidoAbreviacao: 'FAL',
  aprovadoCreche: 'CONCLUINTE',
  aprovadoCrecheAbreviacao: 'CON',
  retencaoJustificada: 'RETENÇÃO JUSTIFICADA',
  retencaoJustificadaAbreviacao: 'RJ',


};
